<template>
  <div>
    <el-form :model="form">
      <el-form-item label="设置积分奖励（按固定值）" label-width="180px">
        <el-input
          placeholder="只能输入数字，如10，就是每消费1元得10积分"
          v-model="form.integralProportionVal"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="设置积分奖励（按百分比）" label-width="180px">
        <el-input
          placeholder="只能输入数字，如40，就是得消费金额40%的积分"
          v-model="form.integralProportionPercentage"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="推广一个洗养订单给多少钱" label-width="230px">
        <el-input
          placeholder="只能输入数字"
          v-model="form.popularizeOrdinary"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="推广一个代驾年检订单给多少钱" label-width="230px">
        <el-input
          placeholder="只能输入数字"
          v-model="form.popularizeNianjian"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="推广一个自驾年检订单给多少钱" label-width="230px">
        <el-input
          placeholder="只能输入数字"
          v-model="form.popularizeZijia"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="首页轮播图" label-width="180px">
        <el-upload
          class="upload-demo"
          action="https://www.jsfgx.online/newcar/newcar/common/upload"
          :on-success="indexImgUploadSuccess"
          :on-remove="removeIndexImgPic"
          :file-list="indexImgFileList"
          :headers="uploadHeaders"
          list-type="picture"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="商家页面轮播图" label-width="180px">
        <el-upload
          class="upload-demo"
          action="https://www.jsfgx.online/newcar/newcar/common/upload"
          :on-success="businessImgUploadSuccess"
          :on-remove="removeBusinessImgPic"
          :file-list="businessImgFileList"
          :headers="uploadHeaders"
          list-type="picture"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="信息服务协议" label-width="180px">
        <el-upload
          class="upload-demo"
          action="https://www.jsfgx.online/newcar/newcar/common/upload"
          :on-success="xinxiImgUploadSuccess"
          :on-remove="removeXinxiImgPic"
          :file-list="xinxiImgFileList"
          :headers="uploadHeaders"
          :data="{ isSystem: 1 }"
          list-type="picture"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="软件使用协议" label-width="180px">
        <el-upload
          class="upload-demo"
          action="https://www.jsfgx.online/newcar/newcar/common/upload"
          :on-success="ruanjianImgUploadSuccess"
          :on-remove="removeRuanjianImgPic"
          :file-list="ruanjianImgFileList"
          :headers="uploadHeaders"
          :data="{ isSystem: 1 }"
          list-type="picture"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="个人信息保护协议" label-width="180px">
        <el-upload
          class="upload-demo"
          action="https://www.jsfgx.online/newcar/newcar/common/upload"
          :on-success="gerenImgUploadSuccess"
          :on-remove="removeGerenImgPic"
          :file-list="gerenImgFileList"
          :headers="uploadHeaders"
          :data="{ isSystem: 1 }"
          list-type="picture"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="用户使用规则协议" label-width="180px">
        <el-upload
          class="upload-demo"
          action="https://www.jsfgx.online/newcar/newcar/common/upload"
          :on-success="guizeImgUploadSuccess"
          :on-remove="removeGuizeImgPic"
          :file-list="guizeImgFileList"
          :headers="uploadHeaders"
          :data="{ isSystem: 1 }"
          list-type="picture"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="委托汽车服务协议" label-width="180px">
        <el-upload
          class="upload-demo"
          action="https://www.jsfgx.online/newcar/newcar/common/upload"
          :on-success="weituoImgUploadSuccess"
          :on-remove="removeWeituoImgPic"
          :file-list="weituoImgFileList"
          :data="{ isSystem: 1 }"
          :headers="uploadHeaders"
          list-type="picture"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
        </el-upload>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="systemSubmit">确 定</el-button>
    </div>
  </div>
</template>
<script>
// import { mapState } from "vuex";
import { updateSystem, getSystem } from "../../api/api";
export default {
  name: "Header",
  data() {
    return {
      indexImgFileList: [],
      businessImgFileList: [],
      xinxiImgFileList: [],
      ruanjianImgFileList: [],
      gerenImgFileList: [],
      guizeImgFileList: [],
      weituoImgFileList: [],
      form: {
        integralProportionVal: "",
        integralProportionPercentage: "",
        popularizeOrdinary: "",
        popularizeNianjian: "",
        popularizeZijia: "",
        indexImageUrl: "",
        businessImageUrl: "",
        xinxiImageUrl: "",
        ruanjianImageUrl: "",
        gerenImageUrl: "",
        guizeImageUrl: "",
        weituoImageUrl: "",
      },
    };
  },
  computed: {
    // 上传时的请求头
    uploadHeaders() {
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
      return { Token: token };
    },
  },
  methods: {
    // 上传首页图片成功的事件
    indexImgUploadSuccess(response) {
      if (response.code === 0) {
        this.indexImgFileList.push({
          name: response.data,
          url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + response.data,
        });
      }
    },
    // 删除首页图片事件
    removeIndexImgPic(file, indexImgFileList) {
      if (file) {
        this.indexImgFileList = indexImgFileList;
      }
    },
    // 上传商家图片成功的事件
    businessImgUploadSuccess(response) {
      if (response.code === 0) {
        this.businessImgFileList.push({
          name: response.data,
          url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + response.data,
        });
      }
    },
    // 删除商家图片事件
    removeBusinessImgPic(file, businessImgFileList) {
      if (file) {
        this.businessImgFileList = businessImgFileList;
      }
    },
    // 上传首页图片成功的事件
    xinxiImgUploadSuccess(response) {
      if (response.code === 0) {
        this.xinxiImgFileList.push({
          name: response.data,
          url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + response.data,
        });
      }
    },
    // 删除首页图片事件
    removeXinxiImgPic(file, xinxiImgFileList) {
      if (file) {
        this.xinxiImgFileList = xinxiImgFileList;
      }
    },
    // 上传首页图片成功的事件
    ruanjianImgUploadSuccess(response) {
      if (response.code === 0) {
        this.ruanjianImgFileList.push({
          name: response.data,
          url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + response.data,
        });
      }
    },
    // 删除首页图片事件
    removeRuanjianImgPic(file, ruanjianImgFileList) {
      if (file) {
        this.ruanjianImgFileList = ruanjianImgFileList;
      }
    },
    // 上传首页图片成功的事件
    gerenImgUploadSuccess(response) {
      if (response.code === 0) {
        this.gerenImgFileList.push({
          name: response.data,
          url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + response.data,
        });
      }
    },
    // 删除首页图片事件
    removeGerenImgPic(file, gerenImgFileList) {
      if (file) {
        this.gerenImgFileList = gerenImgFileList;
      }
    },
    // 上传首页图片成功的事件
    guizeImgUploadSuccess(response) {
      if (response.code === 0) {
        this.guizeImgFileList.push({
          name: response.data,
          url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + response.data,
        });
      }
    },
    // 删除首页图片事件
    removeGuizeImgPic(file, guizeImgFileList) {
      if (file) {
        this.guizeImgFileList = guizeImgFileList;
      }
    },
    // 上传首页图片成功的事件
    weituoImgUploadSuccess(response) {
      if (response.code === 0) {
        this.weituoImgFileList.push({
          name: response.data,
          url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + response.data,
        });
      }
    },
    // 删除首页图片事件
    removeWeituoImgPic(file, weituoImgFileList) {
      if (file) {
        this.weituoImgFileList = weituoImgFileList;
      }
    },
    // 提交系统设置
    systemSubmit() {
      this.form.id = 1;
      let pics = "";
      if (this.indexImgFileList.length > 0) {
        for (let pic of this.indexImgFileList) {
          pics =
            pics +
            pic.url.substring(pic.url.lastIndexOf("/") + 1, pic.url.length) +
            "_";
        }
        pics = pics.substring(0, pics.length - 1);
        this.form.indexImageUrl = pics;
      }

      pics = "";
      if (this.businessImgFileList.length > 0) {
        for (let pic of this.businessImgFileList) {
          pics =
            pics +
            pic.url.substring(pic.url.lastIndexOf("/") + 1, pic.url.length) +
            "_";
        }
        pics = pics.substring(0, pics.length - 1);
        this.form.businessImageUrl = pics;
      }

      pics = "";
      if (this.xinxiImgFileList.length > 0) {
        for (let pic of this.xinxiImgFileList) {
          pics =
            pics +
            pic.url.substring(pic.url.lastIndexOf("/") + 1, pic.url.length) +
            "_";
        }
        pics = pics.substring(0, pics.length - 1);
        this.form.xinxiImageUrl = pics;
      }

      pics = "";
      if (this.ruanjianImgFileList.length > 0) {
        for (let pic of this.ruanjianImgFileList) {
          pics =
            pics +
            pic.url.substring(pic.url.lastIndexOf("/") + 1, pic.url.length) +
            "_";
        }
        pics = pics.substring(0, pics.length - 1);
        this.form.ruanjianImageUrl = pics;
      }

      pics = "";
      if (this.gerenImgFileList.length > 0) {
        for (let pic of this.gerenImgFileList) {
          pics =
            pics +
            pic.url.substring(pic.url.lastIndexOf("/") + 1, pic.url.length) +
            "_";
        }
        pics = pics.substring(0, pics.length - 1);
        this.form.gerenImageUrl = pics;
      }

      pics = "";
      if (this.guizeImgFileList.length > 0) {
        for (let pic of this.guizeImgFileList) {
          pics =
            pics +
            pic.url.substring(pic.url.lastIndexOf("/") + 1, pic.url.length) +
            "_";
        }
        pics = pics.substring(0, pics.length - 1);
        this.form.guizeImageUrl = pics;
      }

      pics = "";
      if (this.weituoImgFileList.length > 0) {
        for (let pic of this.weituoImgFileList) {
          pics =
            pics +
            pic.url.substring(pic.url.lastIndexOf("/") + 1, pic.url.length) +
            "_";
        }
        pics = pics.substring(0, pics.length - 1);
        this.form.weituoImageUrl = pics;
      }

      this.form.popularizeOrdinary = this.form.popularizeOrdinary * 100;
      this.form.popularizeNianjian = this.form.popularizeNianjian * 100;
      this.form.popularizeZijia = this.form.popularizeZijia * 100;
      updateSystem(this.form).then((data) => {
        if (data.code == 0) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.getInfo();
            },
          });
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 获取系统设置
    getInfo() {
      this.indexImgFileList = [];
      this.businessImgFileList = [];
      this.xinxiImgFileList = [];
      this.ruanjianImgFileList = [];
      this.gerenImgFileList = [];
      this.guizeImgFileList = [];
      this.weituoImgFileList = [];
      getSystem({ id: 1 }).then((data) => {
        if (data.code == 0) {
          this.form = data.data;
          this.form.popularizeOrdinary = this.form.popularizeOrdinary / 100;
          this.form.popularizeNianjian = this.form.popularizeNianjian / 100;
          this.form.popularizeZijia = this.form.popularizeZijia / 100;
          for (let pic of data.data.indexImageUrl.split("_")) {
            if (pic) {
              let picture = {
                name: pic,
                url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + pic,
              };
              this.indexImgFileList.push(picture);
            }
          }
          for (let pic of data.data.businessImageUrl.split("_")) {
            if (pic) {
              let picture = {
                name: pic,
                url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + pic,
              };
              this.businessImgFileList.push(picture);
            }
          }
          for (let pic of data.data.xinxiImageUrl.split("_")) {
            if (pic) {
              let picture = {
                name: pic,
                url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + pic,
              };
              this.xinxiImgFileList.push(picture);
            }
          }
          for (let pic of data.data.ruanjianImageUrl.split("_")) {
            if (pic) {
              let picture = {
                name: pic,
                url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + pic,
              };
              this.ruanjianImgFileList.push(picture);
            }
          }
          for (let pic of data.data.gerenImageUrl.split("_")) {
            if (pic) {
              let picture = {
                name: pic,
                url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + pic,
              };
              this.gerenImgFileList.push(picture);
            }
          }
          for (let pic of data.data.guizeImageUrl.split("_")) {
            if (pic) {
              let picture = {
                name: pic,
                url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + pic,
              };
              this.guizeImgFileList.push(picture);
            }
          }
          for (let pic of data.data.weituoImageUrl.split("_")) {
            if (pic) {
              let picture = {
                name: pic,
                url: "https://www.jsfgx.online/newcar/newcar/common/pic/" + pic,
              };
              this.weituoImgFileList.push(picture);
            }
          }
        }
      });
    },
  },
  created() {
    this.selectMenuList = JSON.parse(localStorage.getItem("selectMenuList"));
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo && userInfo.type == 0) {
      // 获取系统设置
      this.getInfo();
    }
  },
};
</script>
<style lang="less" scoped></style>
